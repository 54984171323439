import FuseUtils from '../../../utils/FuseUtils';
// import axios from 'src/utils/axios';
import { api } from '../../../../api';
// import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';
import moment from 'moment';

/* eslint-disable camelcase */
const ACCESS_TOKEN_KEY = 'accessToken';

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    api.interceptors.response.use(
      (response) => response,
      (err) => new Promise((resolve, reject) => {
        if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.emit('onAutoLogout', 'Invalid access_token');
          this.setSession(null);
        }
        throw err;
      })
    );
  };

  getSearchParameters = () => {
    let prmstr = window.location.search.substr(1);
    return prmstr ? this.transformToAssocArray(prmstr) : {};
  };

  transformToAssocArray = (prmstr) => {
    return prmstr.split("&").reduce((params, param) => {
      const [key, value] = param.split("=");
      params[key] = value;
      return params;
    }, {});
  };

  handleAuthentication = async () => {
    let params = this.getSearchParameters();
    const access_token = this.getAccessToken();
    console.log(access_token);

    const token = access_token || params?.token_in;
    if (!token) {
      // this.emit('onNoAccessToken');
      this.emit('onAutoLogout', 'access_token expired');
      return;
    }
    if (this.isAuthTokenValid(token)) {
      this.setSession(token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      api.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
          this.emit('onLogin', response.data.user);
        } else {
          // reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (username, password, snValue) => {
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    return new Promise((resolve, reject) => {
      api.post(jwtServiceConfig.signIn, formData)
        .then((response) => {
          if (response?.status === 200 && response?.data?.access_token) {
            this.handleSuccessfulLogin(response?.data?.access_token, snValue, resolve, reject);
          } else {
            reject(response?.data?.detail);
          }
        })
        .catch((error) => {
          reject("ระบบขัดข้อง กรุณาลองใหม่อีกครั้งในภายหลัง");
        });
    });
  };
  signInWithPIN = (pin, snValue) => {
    return new Promise((resolve, reject) => {
      api.get(`${jwtServiceConfig.verify_pin}/${pin}`, { params: { sn: snValue } })
        .then((response) => {
          console.log((response?.data?.ret === 0 && response?.data?.data?.pin_data?.pin_type == 1));

          if (response?.status === 200 && ((response?.data?.data?.pin_data?.pin_type == 2) || (response?.data?.ret === 0 && response?.data?.data?.pin_data?.pin_type != 2))) {
            const pinData = response?.data?.data?.pin_data;
            const order = response?.data?.data?.order;
            const orderedWithinOneDay = moment().isSameOrBefore(moment(order?.orderedAt).add(1, "d"));

            if (
              (pinData?.pin_type === 1 && orderedWithinOneDay && pinData?.status === 2) ||
              pinData?.pin_type === 2
            ) {
              this.handleSuccessfulLogin(response?.data?.data, snValue, resolve, reject);
            } else if (pinData?.pin_type === 1 && !orderedWithinOneDay) {
              reject("ใบงานเลยวันจัดส่ง");
            } else if (pinData?.pin_type === 1 && pinData?.status === 1) {
              reject("การสั่งสินค้ายังไม่ยืนยัน");
            }
          } else {
            reject(response);
          }

        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  handleSuccessfulLogin = (access_token, snValue, resolve, reject) => {
    this.setSession(access_token?.pin_data?.pin);
    this.getCurrentUser(snValue, access_token?.pin_data?.pin)
      .then((user) => {
        this.emit('onLogin', user);
        resolve(user);
      })
      .catch((error) => {
        reject(error.message);
      });
  };

  getCurrentUser = (snValue, pinValue) => {
    console.log(!snValue && !localStorage.getItem("snValue"));

    if (!snValue && !localStorage.getItem("snValue")) this.logout()
    const dum_snValue = snValue || localStorage.getItem("snValue")
    const dum_pinValue = pinValue || localStorage.getItem(ACCESS_TOKEN_KEY)
    return new Promise((resolve, reject) => {
      api.get(`${jwtServiceConfig.verify_pin}/${dum_pinValue}`, { params: { sn: dum_snValue } })
        .then((response) => {
          const dummy_json = { ...response?.data?.data, "role": response?.data?.data?.role || "admin", device_info: response?.data?.data?.device }
          localStorage.setItem("snValue", dum_snValue);
          resolve(this.transformUserData(dummy_json));
        })
        .catch((error) => {
          // reject(error);
        });
    });
  };
  getDevice = (snValue) => {
    const dum_snValue = snValue
    return new Promise((resolve, reject) => {
      api.get(jwtServiceConfig.device_info, { params: { sn: dum_snValue } })
        .then((response2) => {
          resolve(response2?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  transformUserData = (data) => {
    const permissions = data?.user?.user_role?.user_page_permission || [];
    let authRoles = { onlyGuest: [] };
    // permissions.forEach((permission) => {
    //   authRoles[permission.page_id] = permission?.is_access === 1 ? [data?.user?.user_role?.role_name] : [];
    // });
    console.log(data);

    return {
      ...data,
      authRoles,
      // pagePermission: permissions,
    };
  };
  // signInWithAD = async () => {
  //   const { instance, accounts, inProgress } = useMsal();
  //   const res = instance.loginRedirect(loginRequest);
  // };

  verifyADUser = (token) => {
    console.log("verifyADUser");
    return new Promise((resolve, reject) => {
      // api.post(jwtServiceConfig.accessTokenAD, { token })
      //   .then((response) => {
      //     if (response?.status === 200 && response?.data?.access_token) {
      //       this.handleSuccessfulLogin(response?.data?.access_token, resolve, reject);
      //     } else {
      //       reject(response?.data?.detail);
      //     }
      //   })
      //   .catch((error) => {
      //     this.logout();
      //     reject(new Error('Failed to login with ADtoken.'));
      //   });
    });
  };

  signInWithToken = (token) => {
    return new Promise((resolve, reject) => {
      // api.post(jwtServiceConfig.accessToken, { token })
      //   // api.post(jwtServiceConfig.accessToken, { code:token })
      //   .then((response) => {
      //     if (response?.status === 200 && response?.data?.access_token) {
      //       this.handleSuccessfulLogin(response?.data?.access_token, resolve, reject);
      //     } else {
      //       reject(response?.data?.detail);
      //     }
      //   })
      //   .catch((error) => {
      //     this.logout();
      //     reject(new Error('Failed to login with token.'));
      //   });
    });
  };

  updateUserData = (user) => {
    return api.post(jwtServiceConfig.updateUser, { user });
  };

  isStorageAvailable = (storage) => {
    try {
      const testKey = '__storage_test__';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  setSession = (access_token) => {
    try {
      if (access_token) {
        if (this.isStorageAvailable(localStorage)) {
          localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
        }
        // if (this.isStorageAvailable(sessionStorage)) {
        //   sessionStorage.setItem(ACCESS_TOKEN_KEY, access_token);
        // }

        // Set the authorization header for api
        api.defaults.headers.common.Authorization = `Bearer ${access_token}`;
      } else {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem("snValue");
        // Remove the authorization header for api
        delete api.defaults.headers.common.Authorization;
      }
    } catch (error) {
      console.error('Error accessing localStorage or sessionStorage', error);
      // Handle error as needed
    }
  };


  logout = () => {
    return new Promise((resolve, reject) => {
      // api.post(jwtServiceConfig.logout, {})
      //   .then((response) => {
      //     resolve("ok");
      //   })
      //   .catch((error) => {
      //     reject("ระบบขัดข้อง กรุณาลองใหม่อีกครั้งในภายหลัง");
      //   });
      sessionStorage.clear()
      this.setSession(null);
      this.emit('onLogout', 'Logged out');
    });
  };

  isAuthTokenValid = (access_token) => {
    return !!access_token;
  };

  getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  };
}


const instancea = new JwtService();

export default instancea;
