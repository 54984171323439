import { useEffect, useState } from 'react';
import BrowserRouter from './core/BrowserRouter';
import { useSelector } from 'react-redux';
import { selectUser } from './store/userSlice';
import FuseAuthorization from './core/FuseAuthorization';
import withAppProviders from './withAppProviders';
import { AuthProvider } from './core/auth/AuthContext';
import FuseLayout from './core/FuseLayout';
import themeLayouts from './theme-layouts/themeLayouts';

const App = () => {
  const user = useSelector(selectUser);
  return (
    <AuthProvider>
      <BrowserRouter>
        <FuseAuthorization
          userRole={user?.role}
          device_info={user?.device_info}
          authRoles={user?.authRoles}
          user={user}

          loginRedirectUrl={"/"}
        >
          <FuseLayout layouts={themeLayouts} />
        </FuseAuthorization>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default withAppProviders(App)();

// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import Login from "./Components/Login/Login";
// import FirstSetup from "./Components/FirstSetup/FirstSetup";
// import Locations from "./Components/Location/Locations";
// import Listofparts from "./Components/Listofparts/Listofparts";
// import Manual from "./Components/Manual/Manual";
// import SetupChecking from "./Components/SetupChecking/Setupchecking";
// import FinishSetup from "./Components/FinishSetup/FinishSetup";

// import Login2 from "./Components/Login2/Login2";
// import Welcome from "./Components/Login2/Welcome/Welcome";
// import CheckingStep from "./Components/Login2/CheckingStep/CheckingStep";
// import ReportStep from "./Components/Login2/ReportStep/Reportstep";
// import ReChecking from "./Components/Login2/ReChecking/ReChecking";
// import ReportResult from "./Components/Login2/ReportResult/ReportResult";
// import FinishNegative from "./Components/Login2/FinishNegative/FinishNegative";
// import Supplier from "./Components/Login2/SupplierStocks/Supplier";

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Login2 />} />
//         <Route path="/firstsetup" element={<FirstSetup />} />
//         <Route path="/location" element={<Locations />} />
//         <Route path="/listofparts" element={<Listofparts />} />ผผผปหasass
//         <Route path="/manual" element={<Manual />} />
//         <Route path="/setupchecking" element={<SetupChecking />} />
//         <Route path="/finishsetup" element={<FinishSetup />} />

//         <Route path="/login2" element={<Login2 />} />
//         <Route path="/welcome" element={<Welcome />} />
//         <Route path="/checkingstep" element={<CheckingStep />} />
//         <Route path="/reportstep" element={<ReportStep />} />
//         <Route path="/rechecking" element={<ReChecking />} />
//         <Route path="/reportresult" element={<ReportResult />} />
//         <Route path="/finishnegative" element={<FinishNegative />} />

//         <Route path="/supplier" element={<Supplier />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;
