import Login from '../Login2/Login2';
import authRoles from '../../core/auth/authRoles';

const LoginConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'login',
      element: <Login />,
    },
  ],
};

export default LoginConfig;
